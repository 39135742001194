<template>
  <div>
    <b-card
      no-body
      class="card-payment"
    >
      <b-card-header>
        <b-card-title>
          Mobile App Setting
        </b-card-title>
        <b-card-title>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            to="/app-setting-edit"
          >
            Update
          </b-button>
        </b-card-title>
      </b-card-header>

      <b-card-body>
        <b-table-simple
          v-if="!$store.state.setting.loadingLoad"
          hover
        >
          <b-tbody>
            <b-tr
              v-for="(item, index) in appSetting"
              :key="`list-app-setting-${index}`"
            >
              <b-th>
                {{ index }}
              </b-th>
              <b-td>
                {{ item }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <div v-else>
          <b-skeleton width="85%" />
          <b-skeleton width="55%" />
          <b-skeleton width="70%" />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BSkeleton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BSkeleton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {}
  },
  computed: {
    appSetting() {
      return this.$store.getters['setting/appSettingData']
    },
  },
  mounted() {
    this.$store.dispatch('setting/getAppSetting')
  },
}
</script>
